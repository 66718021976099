<template>
  <footer>
    <hr>
    <BRow
      class="rows"
      no-gutters
    >
      <BCol
        class="logo"
        cols="12"
        md="5"
      >
        <a
          href="http://www.ams.org"
          target="_blank"
        >
          <BImg
            fluid
            :src="logoFooterUrl"
            alt="American Mathematical Society"
          />
        </a>
      </BCol>

      <BCol
        cols="12"
        md="2"
      >
        <!-- <RouterLink
          :to="{name: 'PreviousRelease'}"
          class="btn btn-outline-primary w-100"
        >
          Previous Release
        </RouterLink> -->
        <a
          :href="previousVersionLink"
          target="_blank"
          class="btn btn-outline-primary w-100 ams-link hide-visited-btn"
        >
          Previous Release
        </a>
      </BCol>

      <BCol
        cols="12"
        md="5"
      >
        <ul class="copyright">
          <li>
            <a
              href="https://www.ams.org/ams/copyright.html"
              target="_blank"
              class="ams-link min-accessibility-spacing"
            >
              © {{ currentYear }}, American Mathematical Society
            </a>
          </li>
          <li>
            <a href="https://www.ams.org/ams/privacy.html">
              Privacy Statement
            </a>
            <span>&#183;</span>
            <RouterLink
              :to="{ name: 'TermsOfUse' }"
              @click.native="scrollToTop"
              class="ams-link"
            >
              Terms of Use
            </RouterLink>
            <span>&#183;</span>
            <RouterLink
              :to="{ name: 'Support' }"
              @click.native="scrollToTop"
              class="ams-link"
            >
              Support
            </RouterLink>
          </li>
        </ul>
      </BCol>
    </BRow>
  </footer>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Footer extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  logoFooterUrl = ''

  internal = false
  internalHosts = [ // Could use AppConfigAPI.getAppConfig().internal, but it's slow
    'localhost',
    '127.0.0.1',
    'mrweb.ams.org',
  ]

  //
  // COMPUTED PROPERTIES
  //
  get currentYear() {
    return new Date().getFullYear()
  }

  get previousVersionLink() {
    let baseUrl = '/mathscinet/2006/mathscinet'
    if (this.internal) {
      baseUrl = 'https://mrweb.ams.org/mathscinet'
    }

    const urlParams = new URLSearchParams(window.location.search)
    // Go to old article page
    if (window.location.pathname.indexOf('article') > 0) {
      const mrnumber = urlParams.get('mr') || ''
      if (mrnumber.length > 0) {
        return baseUrl + '/search/publdoc.html?pg1=MR&s1=' + mrnumber
      }
    }

    // Go to old journal/series page
    if (window.location.pathname.indexOf('serials/profile') > 0) {
      const journalId = urlParams.get('journalId') || ''
      const groupId = urlParams.get('groupId') || ''
      const seriesId = urlParams.get('seriesId') || ''
      if (seriesId.length > 0) {
        return baseUrl + '/serials/profile?seriesId=' + seriesId
      }
      if (journalId.length > 0) {
        return baseUrl + '/search/journal/profile?journalId=' + journalId
      }
      if (groupId.length > 0) {
        return baseUrl + '/search/journal/profile?groupId=' + groupId
      }
    }

    // Go to author page
    if (window.location.pathname.indexOf('author') > 0) {
      const authorId = urlParams.get('authorId') || ''
      if (authorId.length > 0) {
        return baseUrl + '/search/author.html?mrauthid=' + authorId
      }
    }

    return baseUrl
  }

  //
  // LIFE CYCLE HOOKS
  //
  mounted() {
    // Loads logo dynamically
    import('@/assets/ams-logo-msn-footer.jpg').then(r => (this.logoFooterUrl = r.default))

    for (const index in this.internalHosts) {
      if (window.location.host.indexOf(this.internalHosts[index]) > -1) {
        this.internal = true
      }
    }
  }

  //
  // METHODS
  //
  scrollToTop() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
// @import 'node_modules/bootstrap/scss/bootstrap.scss';

footer {
  // height: 3.5rem;

  .rows {
    flex-direction: column-reverse;

    > div {
      margin-bottom: 1rem;
    }

    // @media only screen and (min-width: map-get($grid-breakpoints, md)) {
    @media only screen and (min-width: 768px) {
      flex-direction: row;

      > div {
        margin-bottom: 0;
      }
    }
  }

  .logo {
    text-align: center;

    // @media only screen and (min-width: map-get($grid-breakpoints, md)) {
    @media only screen and (min-width: 768px) {
      text-align: left;
    }
  }

  .copyright {
    // font-size: 0.8rem;
    font-size: 0.85rem;
    padding:0;
    margin: 0;
    list-style: none;
    text-align: center;

    // @media only screen and (min-width: map-get($grid-breakpoints, md)) {
    @media only screen and (min-width: 768px) {
      // font-size: 0.7rem;
      text-align: right;
      margin-top: 10px;

    }
  }

  .hide-visited-btn:visited {
    color: #026dde;
  }
  .hide-visited-btn:visited:hover {
    color: white;
  }

  .copyright li {
    margin-bottom: 0.25em;
  }
}
</style>
